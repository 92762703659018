function About(){
    return (
        <div id="about" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s">Apps Exclusivos</h2>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/zazul.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Zazul®</a></h5>
                        <p>Aplicativo para Zona Azul do Estado de SP.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ocr_mobi.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Facial Connect®</a></h5>
                        <p>Gerenciador de acessos por <strong>Reconhecimento Facial</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/qs.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">QS Vídeos®</a></h5>
                        <p>Aplicativo para ensino à <strong>distância</strong> para profissionais da área da saúde.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/iconhahneman.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Pharmacia Hahnemann®</a></h5>
                        <p>Loja virtual para <strong>Redes Sociais</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/usb.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">USB Alarme®</a></h5>
                        <p>Aplicativo cetralizador de notificações de ocorrências perigosas recebidas por <strong>dispositivo Arduíno.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/techhome.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Hello Home®</a></h5>
                        <p>Gerenciador de tarefas para <strong>Automação residencial</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/Logo-Santa-Fe.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">ONG Santa Fé®</a></h5>
                        <p>App desenvolvido para suprir as necessidades gerais da <strong>ONG.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ft9.jpg" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">FT9 Cursos®</a></h5>
                        <p>Aplicativo para gerenciamento de cursos, clientes da empresa <strong>FT9 Cursos®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/favicon-96x96.png" alt="Desenvolvimento de app sob medida"/>
                        <h5><a href="#">Fábrica de Empreendedores®</a></h5>
                        <p>Aplicativo direcionado à Administração Geral da <strong>Plataforma</strong>.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logo-app.png" alt="Aplicativo sob medida para Ponto Digital"/>
                        <h5><a href="#">Viva Desenvolvimento Humano®</a></h5>
                        <p>Aplicativo para gerenciamento de cursos e alunos.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/lps.png" alt="Aplicativo para restaurante"/>
                        <h5><a href="#">Lopes Brooklin®</a></h5>
                        <p>Aplicativo para Corretores</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/lps.png" alt="Aplicativo para restaurante"/>
                        <h5><a href="#">Lopes Brooklin Centralizador®</a></h5>
                        <p>DashBoard integrado para SmartTv</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/comingsoon.png" alt="Aplicativo sob medida para Ponto Digital"/>
                        <h5><a href="#">Ponto Digital®</a></h5>
                        <p>Aplicativo para controle de entrada e saída de funcionários de <strong>Empresas de Segurança</strong>.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/dinno-app.png" alt="Aplicativo para restaurante"/>
                        <h5><a href="#">Dino Ristorante®</a></h5>
                        <p>Aplicativo para restaurante <strong>versão Cliente</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/dinno-app.png" alt="Aplicativo para restaurante"/>
                        <h5><a href="#">Dino Ristorante®</a></h5>
                        <p>Aplicativo para restaurante <strong>versão Restaurante</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logo_ro.jpg" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Assitência 2R®</a></h5>
                        <p>Aplicativo para gerenciamento do negócio</p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/iconhahneman.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Pharmacia Hahnemann  Farmácia®</a></h5>
                        <p>Aplicativo para gerenciamento de <strong>clientes, vendas e produção de fármacos.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/portaria.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Club Life  Portaria®</a></h5>
                        <p><strong>Gerenciador de acessos</strong>,registrador de ocorrências, recebedor de encomendas com emissor de alertas</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/pisc.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Club Life®</a></h5>
                        <p>App desenvolvido para <strong>Síndico e Moradores</strong> com a intenção de trazer mais segurança, agilidade e transparência nos processos de gestão de condomínio</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/pisc2.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Club Life  Piscina®</a></h5>
                        <p>Aplicativo para gerenciamento de acessos e registro de ocorrências para a área da <strong>Piscina</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logoCb.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Conta de Boa®</a></h5>
                        <p>Gerenciador de funcionalidades e tarefas do conjunto de aplicativos <strong>Conta de Boa®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logoCb.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Conta de Boa  Representantes®</a></h5>
                        <p>Aplicativo para Representantes <strong>Conta de Boa®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_papais.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Cantina - Papais®</a></h5>
                        <p>App desenvolvido para os papais gerenciarem o consumo dos filhos na Cantina Escolar <strong>Conta de Boa®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_cantina.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Conta de Boa - Cantina Vendas®</a></h5>
                        <p>Aplicativo direcionado à Cantina, ofertando a possibilidades de vendas de produtos com formato de créditos antecipados e balcão <strong>Conta de Boa®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_barraca.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Conta de Boa  Vendas®</a></h5>
                        <p>Aplicativo para vendedores em geral. Utilizado por barracas de praia, quiosques, bares de quadras, etc... <strong>Conta de Boa.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_adm.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Conta de Boa  Administrador®</a></h5>
                        <p>Aplicativo para gerenciar o negócio (Bar, Quiosque, Cantina, Barraca...) <strong>Conta de Boa.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/w1.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">W1 Luxury Services®</a></h5>
                        <p>App para gerenciamento de serviços de Luxo (Mordomos,Seguranças Pessoais, etc..) em <strong>Londres - UK.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/elite.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Elite Private Girls®</a></h5>
                        <p>Aplicativo direcionado à modelos <strong>Londres - UK</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/balao.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Passeio de Balão®</a></h5>
                        <p>Aplicativo exclusivo da empresa  <strong>Passeio de Balão®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/fgg.jpg" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Dr. Felipe Galleti  Profissionais®</a></h5>
                        <p>Aplicativo para gerenciamento geral do consultório <strong>Dr. Felipe Galleti®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/fgg.jpg" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Dr. Felipe Galleti  Secretária®</a></h5>
                        <p>Aplicativo para funções delegadas à secretária do consultório <strong>Dr. Felipe Galleti®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/rep.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Fábrica de Empreendedores  Representantes®</a></h5>
                        <p>Aplicativo direcionado aos  <strong>Representantes</strong></p>
                        </div>
                    </div>
                </div>

                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/checkup.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">CheckupCam®</a></h5>
                        <p>Detentor da patente <strong>Checkup Digital Preventivo. </strong>Mais que um prontuário eletrônico. <strong>Um propulsor de vendas.</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/gsky-adv.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Advanced®</a></h5>
                        <p>O SKYCAM ADVANCED® é o mais completo sistema do mercado para <strong>diagnóstico por imagem</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/pro.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Pro®</a></h5>
                        <p>Versão intermedária do Software <strong>SkyCam Advanced®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/easy.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Easy®</a></h5>
                        <p>Versão simplificada do Software <strong>SkyCam Advanced®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/skycamone.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam One®</a></h5>
                        <p>Versão para desktops Windows e Mac  <strong>SkyCam Advanced®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/recepacao.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Recepção®</a></h5>
                        <p>Aplicativo para cadastro de pacientes e gerenciamento de anamneses integrado ao <strong>SkyCam®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/palm.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Consultório®</a></h5>
                        <p>Aplicativo para gerenciamento do consultório integrado ao <strong>SkyCam®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/pac.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">SkyCam Pacientes®</a></h5>
                        <p>Aplicativo direcionado aos pacientes cadastrados na plataforma <strong>SkyCam®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/gpk.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">GPK Consultores®</a></h5>
                        <p>Versão para representantes do grupo  <strong>GPK</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/thony.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Thony Hair Studio®</a></h5>
                        <p>Aplicativo direcionado ao gerenciamento do  <strong>Thony Hair Studio®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/thony.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Thony Hair Profissionais®</a></h5>
                        <p>Aplicativo direcionado aos profissionais do  <strong>Thony Hair Studio®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/thony.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Thony Hair Clientes®</a></h5>
                        <p>Aplicativo direcionado aos clientes do  <strong>Thony Hair Studio®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/elemento.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Elemento Dental Studio®</a></h5>
                        <p>App para gerenciamento geral do  <strong>Elemento Dental Studio®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/openG.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Open Air SP Gerenciamento®</a></h5>
                        <p>Aplicativo direcionado ao gerenciamento geral do  <strong>Open Air SP®</strong>. Aulas, Professores, Locações, Clientes, Alunos, Academia e muito mais.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/openBar.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Open Air SP Bar®</a></h5>
                        <p>Aplicativo direcionado ao consumo do bar  <strong>Open Air SP®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/openE.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Open Air SP Vip®</a></h5>
                        <p>Aplicativo direcionado aos eventos exclusivos de clientes <strong>Open Air SP®</strong></p>
                        </div>
                    </div>
                </div>
                <div className="section-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title wow fadeInRight animated" data-wow-delay="0.3s">Apps Livres</h2>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/churras.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Churrasco Interativo</a></h5>
                        <p>App para organização de churrascos entre amigos.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/autotech.jpg" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Auto Tech</a></h5>
                        <p>App para gerenciamento de Oficinas mecânicas.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/gravatela.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Gravador de tela</a></h5>
                        <p>Gravador de tela, especificamente vídeos em tempo real</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/fipe.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Tabela Fipe</a></h5>
                        <p>Aplicativo de preços de automóveis, utilizando como base os dados  <strong>FIPE®</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/barulhometro.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Barulhômetro</a></h5>
                        <p>Barulhômetro é um aplicativo que mede a intensidade dos sons que estão à sua volta e mostra o nível de pressão sonora através de um avatar para que você observe as alterações.
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_cuca.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Treina Cuca</a></h5>
                        <p>Os jogos de memória são uma excelente oportunidade para as crianças melhorarem suas habilidades cognitivas.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logocep1.jpg" alt="Desenvolvimento de Apps Nativos"/>
                        <h5><a href="#">Cep Fácil</a></h5>
                        <p>Criação rotas a partir de um Cep.</p>
                        </div>
                    </div>
                </div>
                <div className="section-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title wow fadeInRight animated" data-wow-delay="0.3s">Apps Comerciais</h2>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logo_cmd_adm.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Comanda Click Gerenciamento</a></h5>
                        <p>Este é o App do gestor do negócio.
                            Com ele você cadastra e autoriza os funcionários a usarem a aplicação, gera relatórios, acompanha o funcionamento do estabelecimento, desempenho de funcionários, acompaha as estatísticas e muito mais.
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logo_cmd_garcon.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Comanda Click Garçon e Atendente</a></h5>
                        <p>Com este App você abre a conta de acordo com a sua necessidade.
                            - Com cadastro
                            - Mesa / Guarda-sol
                            -Digitar número da Comanda
                            - Escanear Comanda
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/lgo_cmd_kds.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Comanda Click KDS</a></h5>
                        <p>App para Cozinha. Este App gerencia e imprime os pedidos recebidos e informa o atendente/garçom que o pedido está pronto.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/tray.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Comanda Click Tablet de Mesa</a></h5>
                        <p>Pedido feito na hora pelo cliente! O autoatendimento permite com que cada cliente customize os produtos do seu cardápio na hora e da maneira que quiser. Os clientes podem avaliar seu estabelecimento ao final de cada experiência.</p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logoArr.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">@ Beleza Estabelecimentos</a></h5>
                        <p>App para gerenciamento de salões de beleza.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logoArr.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">@ Beleza Clientes</a></h5>
                        <p>App para clientes de salões de beleza credenciados na pataforma <strong>@ Beleza</strong>.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/logoArr.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">@ Beleza Profissionais</a></h5>
                        <p>App para profissionais de salões de beleza credenciados na pataforma <strong>@ Beleza</strong>.</p>
                        </div>
                    </div>
                </div>
                <div className="row">    
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_cloudprof.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Cloud Odonto Profissionais</a></h5>
                        <p>App para gerenciamento de todas as atividades de uma Clínica Odontológica. (Com módulo Web)</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_secretaria.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Cloud Odonto Secretária</a></h5>
                        <p>App para gerenciamento de todas as atividades de uma Secretária de Clínica Odontológica. (Com módulo Web)</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12">
                        <div className="about block text-center">
                        <img src="apps/ic_otg.png" alt="Desenvolvimento de Apps"/>
                        <h5><a href="#">Cloud Odonto OTG</a></h5>
                        <p>App para registros de imagens intra oral através de câmera conectada ao dispositvo USB do Equipamento Android.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;