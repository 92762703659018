import HeaderSite from './componentes/header';
import About from './componentes/about';
import ServicesJoin from './componentes/services';
import Portifolio from './componentes/portifolio';
//import FeatureJoinNew from './componentes/feature';
import Counter from './componentes/counter';
import Team from './componentes/team';
import ContatoJoin from './componentes/contato';
import Footer from './componentes/footer';
import PerfumariaPage from './componentes/perfumaria';



function App() {
  return (
      <div>   
      <HeaderSite />
      <Portifolio/>
      <ServicesJoin/>
      <About/>
      <Counter/>
      <Team/>
      <ContatoJoin/>
      <Footer/>
      <PerfumariaPage/>
      </div>
  );
}
export default App;
