function ServicesJoin(){
    return(
<section id="services" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s">Como usamos as tecnologias</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-cogs"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Python</a></h4>
                <p>
                  Utilizamos Python no BackEnd em aplicações que trabalham com um grande volume de informação 
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-cubes"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Javascript</a></h4>
                <p>
                  Utilizamos javascript basicamente em tudo o que projetamos.Atualmente é a linguagem de programação mais popular no desenvolvimento web, sendo suportada por todos os navegadores oferece dinamismo e resultados impressionantes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-tachometer"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">NodeJS</a></h4>
                <p>
                 Utilizamos NodeJs no BackEnd em Apis para disponibilizar as informações entre sistemas.Ele é muito utilizado devido a facilidade na construção de aplicações de rede rápidas e escaláveis.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-database"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">MongoDB</a></h4>
                <p>
                O MongoDB Server é um banco de dados de uso geral projetado para servir a maioria do OLTP e da carga de trabalho de análise online. O utilizamos para guardar os dados e serem disponibilizados para Apis construídas em NodeJS
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-database"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">MySql</a></h4>
                <p>
                 É o Banco de Dados mais utilizado no mundo. Utilizamos em aplicações web de pequeno e médio porte.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-file-code-o"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Kotlin</a></h4>
                <p>
                  Utilizamos Kotlin para o desenvolvimento de aplicativos <strong>NATIVOS</strong> para a plataforma Android.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-file-code-o"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Swift</a></h4>
                <p>
                Utilizamos Swift para o desenvolvimento de aplicativos <strong>NATIVOS</strong> para a plataforma iOS.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-html5"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Html5</a></h4>
                <p>
                HTML5 é uma linguagem de marcação que utilizamos para criar a estrutura de uma página da <strong>web</strong>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="service-box">
              <div className="service-icon">
                <i className="fa fa-css3"></i>
              </div>
              <div className="service-content">
                <h4><a href="#">Css3</a></h4>
                <p>
                  Utilizamos o CSS para separar o conteúdo da representação visual do site. É ele quem deixa tudo organizado e bonito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}
export default ServicesJoin;