
function HeaderSite (){
  
    return(
    <header id="header-wrap">
      <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
        <div className="container">
          <div className="navbar-header">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              <span className="icon-menu"></span>
              <span className="icon-menu"></span>
              <span className="icon-menu"></span>
            </button>
            <a href="/" className="navbar-brand"><img src="img/logo.png" alt="TechJoin - Desenvolvimento de Software"/></a>
          </div>
          <div className="collapse navbar-collapse" id="main-navbar">
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              <li className="nav-item active">
                <a className="nav-link" href="#sliders">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#portfolio">
                O que Fazemos
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Tecnologias
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  Apps
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">
                  Destaques
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contato
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul className="mobile-menu navbar-nav" id="mb">
          <li>
            <a className="page-scroll" href="#sliders">
              Home
            </a>
          </li>
          <li>
            <a className="page-scroll" href="#portfolio">
              O que Fazemos
            </a>
          </li>
          <li>
            <a className="page-scroll" href="#services">
              Tecnologias
            </a>
          </li>
          <li>
            <a className="page-scroll" href="#about">
            Apps
            </a>
          </li>
          <li>
            <a className="page-scroll" href="#team">
            Destaques
            </a>
          </li>
          <li>
            <a className="page-scroll" href="#contact">
              Contato
            </a>
          </li>
        </ul>
      </nav>
      <div id="sliders">
        <div className="full-width">
          <div id="light-slider" className="carousel slide">
            <div id="carousel-area">
              <div id="carousel-slider" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <img src="img/slider/fundo1.jpg" alt="TechJoin "/>
                    <div className="carousel-caption">
                      <h3 className="slide-title animated fadeInDown"><span>TECHJOIN</span> <br/></h3>
                      <h5 className="slide-text animated fadeIn">Mais de 150 aplicativos construídos para empresas de diversos portes e segmentos</h5>
                      <a href="/developer/" className="btn btn-lg btn-common animated fadeInUp">Área do  Dev</a>
                      <a href="/clientes/" className="btn btn-lg btn-border animated fadeInUp">Área do Cliente</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    )
  }

  export default  HeaderSite;