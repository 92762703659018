function PerfumariaPage(){
    return (
        <>
        <a href="#" className="back-to-top">
            <i className="fa fa-arrow-up"></i>
        </a>
        <div id="preloader">
            <div className="loader" id="loader-1"></div>
        </div>
        </>
    )
}
export default PerfumariaPage