function Portifolio(){
    return (
        <section id="portfolio" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s">O que fazemos </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="controls text-center wow fadeInUpQuick" data-wow-delay=".6s">
              <a className="filter active btn btn-common" data-filter="all">
              WEBSITES 
              </a>
              <a className="filter btn btn-common" data-filter="all">
              PWA 
              </a>
              <a className="filter btn btn-common" data-filter="all">
              APPS MOBILE
              </a>
              <a className="filter btn btn-common" data-filter="all">
              SMART TVS 
              </a>
              <a className="filter btn btn-common" data-filter="all">
              INTEGRAÇÃO 
              </a>
              <a className="filter btn btn-common" data-filter="all">
              MANUTENÇÃO 
              </a>
            </div>
          </div>
          <div id="portfolio" className="row wow fadeInUpQuick" data-wow-delay="0.8s">
            <div className="col-lg-4 col-md-6 col-xs-12 mix marketing planning">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img1.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                        
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>WEBSITES</h4>
                        </a>
                        <p className="sup-description">Como o conteúdo na era digital é o que destaca um negócio para os consumidores, os sites são um elemento importante nas estratégias de Marketing Digital.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mix branding planning">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img2.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                        
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>PROGRESSIVE WEB APP</h4>
                        </a>
                        <p className="sup-description">Em resumo, é uma aplicação web com tecnologias que permitem termos a experiência de uso muito próxima da oferecida pelos mobile apps.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mix branding research">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img3.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                       
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>APPS MOBILE</h4>
                        </a>
                        <p className="sup-description">Ao disponibilizar um aplicativo, a empresa estreita os laços com os clientes e permite que eles se aproximem cada vez mais do seu negócio. Ao contrário do site, que é um ambiente no qual as pessoas procuram por informações, o aplicativo é um sistema de interação constante.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mix marketing research">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img4.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                        
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>SMART TVS</h4>
                        </a>
                        <p className="sup-description">Além de você ou sua empresa ter seu produto ou serviço presente em mais um segmento relevante, a grande vantagem em ter seu app nas appstores, consiste no simples fato da baixa concorrência entre so apps disponíveis para Smart TVs</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mix marketing planning">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img5.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                        
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>INTEGRAÇÃO ENTRE PLATAFORMAS</h4>
                        </a>
                        <p className="sup-description">Integrar sistemas é basicamente unificar diferentes softwares para consolidar a troca de informações entre eles. Isso pode ser feito de algumas formas: através da incorporação do banco de dados, do compartilhamento eletrônico das informações ou de API.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mix planning research">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img src="img/portfolio/img6.jpg" alt="" />
                </div>
                <div className="portfoli-content">
                  <div className="sup-desc-wrap">
                    <div className="sup-desc-inner">
                      <div className="sup-link">
                        
                      </div>
                      <div className="sup-meta-wrap">
                        <a className="sup-title" href="#">
                          <h4>MANUTENÇÃO DE SOFTWARE</h4>
                        </a>
                        <p className="sup-description">Um dos principais objetivos em realizar a manutenção de software é o aperfeiçoamento tecnológico do sistema. Ao realizar essa atividade, o sistema se tornará cada vez mais estável, diminuindo sua velocidade de envelhecimento.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}
export default Portifolio