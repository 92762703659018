export default function Footer(){
    return (
        <footer className="footer">
        <div id="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12">
                <p className="copyright-text">Todos os direitos reservados © 2022 - Design &amp; Desenvolvimento by <a href="#">TechJoin</a>
                </p>
              </div>
            </div>
          </div>
        </div>
    </footer>
    )
}