function Team(){
    return (
        <div id="team" className="team-members-tow section-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s">Destaques</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-xs-12">
          <figure>
          <img src="img/team/team-05.jpg" alt="Desenvolvimento de Apps"/>
            <div className="image-overlay">
              <div className="overlay-text text-center">
                <div className="info-text">
                  <strong>Fábrica de Empreendedores</strong>
                  <span>Respresentantes</span>
                </div>
                <hr className="small-divider"/>
                <div className="social">
                O app disponibiliza uma interface limpa e de fácil entendimento, possibilitando o manuseio de usuários que estão ingressando agora no mercado de trabalho ou profissionais experientes na área.
                </div>
              </div>
            </div>
          </figure>
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <figure>
            <img src="img/team/team-06.jpg" alt="Desenvolvimento de Apps"/>
            <div className="image-overlay">
              <div className="overlay-text text-center">
                <div className="info-text">
                  <strong>Comanda Click</strong>
                  <span>Conjunto de Apps</span>
                </div>
                <hr className="small-divider"/>
                <div className="social">
                O Comanda Click possui ferramentas imprescindíveis para aprimorar o atendimento e a gestão do seu bar, quiosque, barracas de praia ou restaurante. Com elas, algumas tarefas se tornam automatizadas e sobra mais tempo para administrar outros setores do seu negócio.
                </div>
              </div>
            </div>
          </figure>
        </div>

        <div className="col-lg-4 col-md-6 col-xs-12">
          <figure>
            <img src="img/team/team-07.jpg" alt="Desenvolvimento de Apps"/>
            <div className="image-overlay">
              <div className="overlay-text text-center">
                <div className="info-text">
                  <strong>Barulhômetro</strong>
                  <span>Decibelímetro</span>
                </div>
                <hr className="small-divider"/>
                <div className="social">
                Barulhômetro é um aplicativo que mede a intensidade dos sons que estão à sua volta e mostra o nível de pressão sonora através de um avatar para que você observe as alterações.
                </div>
              </div>
            </div>
          </figure>
    
        </div>
      </div>
    </div>
  </div>
    )
}
export default Team