function Counter(){
    return (
        <div id="counter">
          <div className="container">
            <div className="row count-to-sec">
              <div className="col-lg-3 col-md-6 col-xs-12 count-one">
                <span className="icon"><i className="fa fa-download"> </i></span>
                <h3 className="timer count-value" data-to="100000" data-speed="1000">100.000</h3>
                <hr className="width25-divider"/>
                <small className="count-title">Downloads</small>
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12 count-one">
                <span className="icon"><i className="fa fa-user"> </i></span>
                <h3 className="timer count-value" data-to="150" data-speed="1000">150</h3>
                <hr className="width25-divider"/>
                <small className="count-title">Clientes satisfeitos</small>
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12 count-one">
                <span className="icon"><i className="fa fa-desktop"> </i></span>
                <h3 className="timer count-value" data-to="30000000" data-speed="1000">30.000.000</h3>
                <hr className="width25-divider"/>
                <small className="count-title">Linhas de código escritas</small>
              </div>

              <div className="col-lg-3 col-md-6 col-xs-12 count-one">
                <span className="icon"><i className="fa fa-coffee"> </i></span>
                <h3 className="timer count-value" data-to="1700" data-speed="1000">1700</h3>
                <hr className="width25-divider"/>
                <small className="count-title">Litros de Café consumidos</small>
              </div>
            </div>
          </div>
        </div>
    )
}
export default Counter