import React, { Component } from 'react';

class ContatoJoin extends Component {
  constructor() {
    super();
    this.state = {
      name:'',
      email:'',
      telefone:'',
      message:'',
      resposta: 'Enviar Mensagem'
    };
    
    
  }
  
  onChange = (e) => {
    this.setState({ 
      [e.target.name]: e.target.value 
    });
  }
  
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ 
      resposta:'Enviando...'
    });
    
    const { name, email, telefone, message } = this.state;
    const urlP = `name=${name}&telefone=${telefone}&email=${email}&message=${message}`;
    this.setState({ 
      name:'',
      email:'',
      telefone:'',
      message:'',
      resposta: 'Mensagem enviada!'
    });
    fetch('https://techjoin.com.br/techjoin.php?'+urlP, {
    method: 'GET',
    headers:{
        "Content-Type": "application/x-www-form-urlencoded"
    }
    }).then(function (data) {
      //this.onFinaliza()
      console.log('@@    ', data);
    })
    .catch (function (error) {
        console.log('@@    ', error);
        //this.onFinaliza()
    });
  }

  

  render() {
    
    return (
      <section id="contact" className="contact-form section-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s">Contato</h2>
        </div>
      </div>
      <div className="row">
      <div className="col-lg-4 col-md-6 col-xs-12">
          <h4 className="contact-info-title text-left"></h4>
          <img src="img/NewLogoClaro.png" alt="Desenvolvimento de Apps"/>
          <div className="contact-info">
            <a href="#"><i className="lni-tab icons cyan-color contact-info-icon"></i></a>
            <ul className="social-links">
              <li>
                <a href="https://www.linkedin.com/in/daniel-alarcon-b4487618a/" target="_blank" className="fa fa-linkedin"></a>
                 
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-xs-12">
          <h3 className="title-head text-left">Nos envie uma mensagem</h3>
          <form id="contatoForm" className="contact-form">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="form-group">
                  <i className="contact-icon fa fa-user"></i>
                  <input type="text" className="form-control" value={this.state.name} id="name"  name="name" onChange={this.onChange} placeholder="Nome completo" required data-error="Informe o seu nome"/>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="form-group">
                  <i className="contact-icon fa fa-envelope-o"></i>
                  <input type="email" className="form-control" value={this.state.email} id="email" name="email"  onChange={this.onChange} placeholder="E-mail" required data-error="Informe seu e-mail"/>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="form-group">
                  <i className="contact-icon fa fa-pencil-square-o"></i>
                  <input type="tel" className="form-control" value={this.state.telefone} id="telefone"  name="telefone"   onChange={this.onChange} placeholder="Celular" required data-error="Por favor, informe seu celular"/>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12">
                <textarea className="form-control" value={this.state.message} id="message" name="message"  onChange={this.onChange} rows="4" placeholder="Mensagem" required data-error="Informe a sua mensagem aqui"></textarea>
                <div className="help-block with-errors"></div>
                <a id="form-submit" onClick={this.onSubmit} className="btn btn-common btn-form-submit">{this.state.resposta}</a>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </section>
    );
  }
}

export default ContatoJoin;